<template>
  <div>
    <v-card class="blog-card" color="primary">
      <v-row>
        <v-col cols="12" xl="7" lg="7" md="6" sm="6">
          <v-row>
            <v-card-text>{{ thePost.title }}</v-card-text>
            <v-card-text>{{ thePost.description }}</v-card-text>
          </v-row>
          <v-row>
            <v-card-text v-html="thePost.contents"></v-card-text>
          </v-row>
        </v-col>
        <v-col cols="12" xl="5" lg="5" md="6" sm="6">
          <div class="detail">
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail" v-if="thePost.createdBy.id != 0">
                By: {{ thePost.createdBy.firstName }}
                {{ thePost.createdBy.lastName }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail">
                Created: {{ thePost.createdOn.substring(0, 10) }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail">
                Published: {{ thePost.publishedOn.substring(0, 10) }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail">
                Modified: {{ thePost.lastModifiedOn.substring(0, 10) }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div
                v-for="category in thePost.categories"
                v-bind:key="category.id"
              >
                <div>Categories: {{ category.title }}</div>
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div v-for="tag in post.tags" v-bind:key="tag.id">
                <div>Tags: {{ tag.title }}</div>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn class="post-view" icon @click="onView(post.id)"
          ><v-icon left>mdi-post</v-icon></v-btn
        >
      </v-row>
    </v-card>
  </div>
</template>

<script>
import router from "@/router"

export default {
  props: {
    post: {
      required: true,
      default: {},
    },
    level: Number,
  },

  created() {
    this.thePost = this.post;
  },

  data: () => ({
    thePost: {},
  }),

  methods: {
    onView(id) {
      router.push('/blog/' + id)
    }
  }
};
</script>

<style>
.blog-card {
  padding: 5px;
  margin: 5px;
}
.comment-detail {
  font-size: 0.65em;
}
.detail {
  padding-top: 10px;
  padding-right: 20px;
}
.post-view {
  padding-right: 5px;
  padding-bottom: 20px;
}
</style>