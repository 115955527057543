<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
        <v-row>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            v-for="post in pagedPosts"
            v-bind:key="post.id"
          >
            <blog-card :post="post"></blog-card>
          </v-col>
        </v-row>

        <v-pagination
          v-model="page"
          :length="pages"
          @input="onPageChanged"
          v-if="pagedPosts.length > 0"
        >
        </v-pagination>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-row>
          <h2>Filter</h2>
        </v-row>
        <v-row>
          <category-ctrl
            allowAdd
            :selectedCategories="selectedCategories"
            @change="onCategoriesChanged"
          ></category-ctrl>
        </v-row>
        <v-row>
          <tags-ctrl
            allowAdd
            :selectedTags="selectedTags"
            @change="onTagsChanged"
          ></tags-ctrl>
        </v-row>
        <v-row>
          <v-select
            class="per-page"
            v-model="selectedItemsPerPage"
            :items="perPageItems"
            item-text="text"
            item-value="value"
            label="Items Per Page"
            @change="onItemsPerPageChanged"
          ></v-select>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete the selected article?</v-card-title>
        <v-card-text>{{ title }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined text @click="dialog = false">No</v-btn>
          <v-btn outlined text @click="deleteArticle">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import blogCard from "./blog-card"
import router from "@/router"
import store from "@/store"
import tags from "@/components/controls/Tags"
import categories from "@/components/controls/Categories"

export default {
  name: "Blog",
  components: {
    "tags-ctrl": tags,
    "category-ctrl": categories,
    "blog-card": blogCard,
  },

  created() {
    this.get();
  },

  data: () => ({
    afterGet: false,
    categoriesMessage: "Select All",
    categories: [],
    dialog: false,
    item: {
      id: 0,
      tags: [],
    },
    id: 0,
    page: store.getters.getPostSelectedPage,
    pagedPosts: [],
    pages: 0,
    posts: [],
    selectedCategories: [],
    selectedItemsPerPage: 10,
    selectedTags: [],
    tags: [],
    tagsMessage: "Select All",
    title: "",
    perPageItems: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }),

  methods: {
    deleteArticle() {
      this.dialog = false;
      if (this.id > 0) {
        // BlogService.delete(this.id).then((result) => {
        //   this.$refs.alert.displayActionResult(result);
        //   this.fetchBlogs();
        // });
      }
    },

    get() {
      this.pagedBlogs = [];
      apiService.get("post", this.getSuccess, this.getError);
    },

    getError(error) {
      const notification = {
        title: "Error getting the posts",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(result) {
      this.posts = result.data;
      this.selectedCategories = store.getters.getPostSelectedCategories
      this.selectedItemsPerPage = store.getters.getPostItemsPerPage
      this.selectedTags = store.getters.getPostSelectedTags
      this.afterGet = true
      this.updateBlogs()
    },

    onCategoriesChanged(selected) {
      if (this.afterGet) {
        this.selectedCategories = selected;
        this.updateBlogs();
        store.commit("setPostSelectedCategories", this.selectedCategories);
      }
    },

    onDelete(id) {
      this.dialog = true;
      // Find the blog
      const article = this.blogs.find((n) => n.id === id);
      this.title = "";
      this.id = 0;
      if (article) {
        this.id = article.id;
        this.title = article.title;
      }
    },

    onEdit(id) {
      router.push("/Blog/Edit/" + id);
    },

    onFilter() {
      this.blogDrawer = !this.blogDrawer;
      this.filterIcon = this.blogDrawer ? "mdi-arrow-left" : "mdi-arrow-right";
    },

    onItemsPerPageChanged() {
      if (this.afterGet) {
        this.updateBlogs();
        store.commit("setPostSelectedItemsPerPage", this.selectedItemsPerPage);
      }
    },

    onTagsChanged(selected) {
      if (this.afterGet) {
        this.selectedTags = selected;
        this.updateBlogs();
        store.commit("setPostSelectedTags", this.selectedTags);
      }
    },

    onPageChanged() {
      store.commit("setPostSelectedPage", this.page);
      this.updateBlogs();
    },

    onView(id) {
      router.push("/Blog/" + id);
    },

    updateBlogs() {
      console.log("updateBlogs()");
      this.pagedPosts = [];
      let tempPosts = [];

      if (
        this.selectedCategories.length == 0 &&
        this.selectedTags.length == 0
      ) {
        tempPosts = this.posts;
      } else {
        this.posts.forEach((post) => {
          let add = false;
          post.categories.forEach((category) => {
            this.selectedCategories.forEach((selCat) => {
              if (category.id === selCat.id) {
                add = true;
              }
            });
          });
          post.tags.forEach((tag) => {
            this.selectedTags.forEach((selTag) => {
              if (tag.id === selTag.id) {
                add = true;
              }
            });
          });

          if (add) {
            tempPosts.push(post);
          }
        });
      }

      var remainder = tempPosts.length % this.selectedItemsPerPage > 0 ? 1 : 0;
      this.pages = parseInt(
        tempPosts.length / this.selectedItemsPerPage + remainder
      );
      if (this.page > this.pages) {
        this.page = this.pages;
        if (this.page < 1) {
          this.page = 1;
        }
      }
      console.log("Pages:", this.pages);
      var start = (this.page - 1) * this.selectedItemsPerPage;
      var end = start + this.selectedItemsPerPage;
      if (end > tempPosts.length) {
        end = tempPosts.length;
      }
      for (var i = start; i < end; i++) {
        this.pagedPosts.push(tempPosts[i]);
      }
    },
  },

  store,
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.cards {
  padding-bottom: 25px;
}
.cat-tag {
  padding-left: 20px;
}
.per-page {
  padding-left: 14px;
  padding-right: 13px;
}
</style>
