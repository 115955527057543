<template>
  <v-container>
    <v-data-table :headers="headers" :items="logos" item-key="id">
      <template v-slot:[`item.selected`]="{ item }">
        <v-checkbox
          v-model="item.selected"
          @change="onSelected(item)"
        ></v-checkbox>
      </template>
      <template v-slot:[`header.delete`]>
        <v-btn
          outlined
          @click="onEdit(0)"
        >
          <v-icon left>mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn outlined @click="onDelete(item.id)">
          <v-icon left>mdi-delete</v-icon>Delete
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>Delete Logo</v-card-title>
        <v-card-subtitle>Name: {{ logo.name }}</v-card-subtitle>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="button" icon outline @click="onNo">
              <v-icon>mdi-close</v-icon>No
            </v-btn>
            <v-btn class="button" icon outline @click="onYes">
              <v-icon>mdi-delete</v-icon>Yes
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import router from "@/router"
import store from "@/store"

export default {
  components: {
  },

  created() {
    this.get();
  },

  data: () => ({
    allowAdd: true,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Selected",
        align: "start",
        sortable: true,
        value: "selected",
      },
      {
        text: "",
        filterable: false,
        sortable: false,
        value: "delete",
        width: 60,
      },
    ],
    logo: {},
    logos: [],
    showDialog: false,
    url: "logo",
  }),

  methods: {
    get() {
      this.logos = [];
      apiService.get(this.url, this.getSuccess, this.getError);
    },

    getSuccess(result) {
      this.logos = result.data;
    },

    getError(error) {
      const notification = {
        title: "Error getting the logos",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onAdd() {
      router.push("/logo/edit/0");
    },

    onDelete(id) {
      console.log("Delete : ", id);
      this.showDialog = true;
      this.logo = this.logos.find((i) => i.id == id);
    },

    onEdit(id) {
      console.log("onEdit ", id)
       router.push('/logo/' + id)
    },

    onNo() {
      this.showDialog = false;
    },

    onSelected(logo){
      const url = "logo/" + logo.id
      apiService.put(url, logo, this.saveSuccess, this.saveError)
    },
    
    saveSuccess(result){
      console.log('Logo saved: ', result.data);
    },

    saveError(error) {
      console.log('Save Logo error: ', error);
    },

    onYes() {
      this.showDialog = false;
      const url = "logo/" + this.logo.id;
      apiService.delete(url, this.deleteSuccess, this.deleteError);
    },

    deleteSuccess() {
      this.showDialog = false;
      const notification = {
        title: "Logo Deleted",
        status: "success",
        messages: [this.logo.name + " deleted"],
      };
      store.commit("setNotification", notification);
      this.get();
    },

    deleteError(error) {
      const notification = {
        title: "Error deleting the logo",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },

  router,
  store,
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 50px;
}
</style>