var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Posts")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.posts,"item-key":"id"},scopedSlots:_vm._u([{key:"header.edit",fn:function(){return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onEdit(0)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add ")],1)]},proxy:true},{key:"item.isPublished",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.isPublished),callback:function ($$v) {_vm.$set(item, "isPublished", $$v)},expression:"item.isPublished"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.createdBy.firstName)+" "+_vm._s(item.createdBy.lastName))]}},{key:"item.commentCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.comments.length))]}},{key:"item.tagCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tags.length))]}},{key:"item.categoryCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.categories.length))]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.convertDate(item.createdOn, false)))]}},{key:"item.publishedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.convertDate(item.publishedOn, false)))]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onEdit(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onDelete(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("Delete ")],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }