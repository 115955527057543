export default {
  convertDate(dateString, full) {
    var result = ''

    if (dateString != undefined) {
      var theDate = new Date(dateString)
      const year = theDate.getFullYear()
      const month = theDate.getMonth() + 1
      const day = theDate.getDate()
      let hour = theDate.getHours()
      const minute = theDate.getMinutes()
      const am = hour < 12 ? 'AM' : 'PM'
      if (hour > 12) {
        hour = hour - 12
      }
      result = month + '/' + day + '/' + year
      if (full) {
        result =
          result +
          ' ' +
          hour +
          ':' +
          minute.toString().padStart(2, '0') +
          ' ' +
          am
      }
    }
    return result
  },
}
