<template>
  <v-container>
    <v-card class="blog-card" color="primary">
      <v-row>
        <v-col cols="12" xl="10" lg="10" md="9" sm="8">
          <v-row>
            <v-col cols="12" xl="3" lg="3" md="3" sm="4">
              <v-card-text>{{ post.title }}</v-card-text>
            </v-col>
            <v-col cols="12" xl="9" lg="9" md="9" sm="8">
              <v-card-text>{{ post.description }}</v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text v-html="post.contents"></v-card-text>
          </v-row>
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="3" sm="4">
          <div class="detail">
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail" v-if="post.createdBy.id != 0">
                By: {{ post.createdBy.firstName }}
                {{ post.createdBy.lastName }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail">
                Created: {{ post.createdOn.substring(0, 10) }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail">
                Published: {{ post.publishedOn.substring(0, 10) }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div class="comment-detail">
                Modified: {{ post.lastModifiedOn.substring(0, 10) }}
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div v-for="category in post.categories" v-bind:key="category.id">
                <div class="cat-tag">
                  Categories: {{ category.title }}
                </div>
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <div v-for="tag in post.tags" v-bind:key="tag.id">
                <div class="cat-tag">
                  Tags: {{ tag.title }}
                </div>
              </div>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn icon text @click="addComment" v-if="allowAdd"
                ><v-icon left>mdi-plus</v-icon></v-btn
              >
              <v-btn icon text @click="onBack"
                ><v-icon left>mdi-backspace</v-icon></v-btn
              >
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row v-for="comment in post.comments" v-bind:key="comment.id">
      <post-comment
        :level="1"
        :comment="comment"
        @cancel="onCancel"
        @delete="onChildDeleted"
        @saved="onCommentSaved"
      ></post-comment>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service.js"
import comment from "./Comment"
import registry from "@/_services/registry-service"
import store from "@/store"
import router from "@/router"

export default {
  name: "View-Blog",
  components: {
    "post-comment": comment,
  },
  created() {
    this.isBlogger = this.$store.getters.isBlogger
    this.isLoggedIn = this.$store.getters.getUser.LoggedIn
    if(this.allowComments) {
      if(this.allowAnonymousComments || this.isLoggedIn) {
        this.allowAdd = true
      }
    }
    this.allowDelete = this.isBlogger
    this.allowEdit = this.isBlogger
    this.get();
  },

  data: () => ({
    allowComments: registry.getBool("blog_allow_comments"),
    allowAnonymousComments: registry.getBool("blog_allow_anonymous_comments"),
    allowEdit: false,
    allowAdd: false,
    allowDelete: false,
    isBlogger: false,
    isLoggedIn: false,
    post: {
      id: 0,
      createdBy: {
        firstName: "",
        lastName: "",
      },
      createdOn: "",
      contents: "",
      description: "",
      lastModifiedOn: "",
      publishedOn: "",
    },
  }),

  methods: {
    get() {
      if (this.$route.params.id > 0) {
        const url = "post/" + this.$route.params.id;
        apiService.get(url, this.getSuccess, this.getError);
      }
    },

    getError(error) {
      console.log(error);
    },

    getSuccess(result) {
      this.post = result.data;
    },

    onBack() {
      router.go(-1);
    },

    onCancel(id) {
      console.log("onCancel() ", id);
      this.post.comments.pop();
    },

    onCommentSaved(data) {
      const comment = this.post.comments.find(c => c.id = data.oldId)
      if(comment){
        comment.id = data.newId
      }
    },

    onChildDeleted(id) {
      const index = this.post.comments.map((c) => c.id).indexOf(id);
      if (index > -1) {
        this.post.comments.splice(index, 1);
      }
    },

    addComment() {
      console.log("addComment()");

      const user = this.$store.getters.getUser;
      let userId = user == null ? null : user.id;
      user.firstName = user.firstname;
      user.lastName = user.lastname;
      const comment = {
        id: 0,
        postId: this.post.id,
        contents: "change me",
        createdBy: user,
        userId: userId,
        parentId: null,
        idPublished: true,
        createdOn: new Date().toISOString().substr(0, 10),
        lastModifiedOn: new Date().toISOString().substr(0, 10),
        publishedOn: new Date().toISOString().substr(0, 10),
      };
      this.post.comments.push(comment);
    },
  },

  store,
  router,
};
</script>

<style lang="scss" scoped>
#content img {
  max-width: 100%;
  height: auto;
}
.align-right {
  text-align: right;
}
.blog-card {
  padding: 10px;
}
</style>
