<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="button" icon v-if="allowAdd" @click="onAdd">
        <v-icon>mdi-plus</v-icon>Add Video
      </v-btn>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="4"
        sm="6"
        v-for="video in videos"
        v-bind:key="video.id"
      >
        <div v-if="video.type == 'video/mp4'">
          <video-card :video="video" @delete="onDelete(video.id)"></video-card>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>Delete Video</v-card-title>
        <v-card-subtitle>Title: {{ video.title }}</v-card-subtitle>
        <v-card-text>Description: {{ video.description }}</v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="button" icon outline @click="onNo">
              <v-icon>mdi-close</v-icon>No
            </v-btn>
            <v-btn class="button" icon outline @click="onYes">
              <v-icon>mdi-delete</v-icon>Yes
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import router from "@/router";
import store from "@/store";
import videocard from "@/views/Video/VideoCard";

export default {
  components: {
    "video-card": videocard,
  },

  created() {
    this.get();
  },

  data: () => ({
    allowAdd: true,
    video: {},
    videos: [],
    showDialog: false,
    url: "video",
  }),

  methods: {
    get() {
      this.videos = [];
      apiService.get("video", this.getSuccess, this.getError);
    },

    getSuccess(result) {
      this.videos = result.data;
    },

    getError(error) {
      const notification = {
        title: "Error getting the videos",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onAdd() {
      router.push("/video/edit/0");
    },

    onDelete(id) {
      console.log("Delete : ", id);
      this.showDialog = true;
      this.video = this.videos.find((i) => i.id == id);
    },

    onNo() {
      this.showDialog = false;
    },

    onYes() {
      this.showDialog = false;
      const url = "video/" + this.video.id;
      apiService.delete(url, this.deleteSuccess, this.deleteError);
    },

    deleteSuccess() {
      this.showDialog = false;
      const notification = {
        title: "Video Deleted",
        status: "success",
        messages: [this.video.title + " deleted"],
      };
      store.commit("setNotification", notification);
      this.get();
    },

    deleteError(error) {
      const notification = {
        title: "Error deleting the video",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },

  router,
  store,
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 50px;
}
</style>