<template>
  <v-container>
    <v-data-table :headers="headers" :items="settings" item-key="id">
      <template v-slot:[`header.edit`]>
        <v-btn outlined @click="onEdit(0)">
          <v-icon left>mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn outlined @click="onEdit(item.id)">
          <v-icon left>mdi-pencil</v-icon>Edit
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn outlined @click="onDelete(item.id)">
          <v-icon left>mdi-delete</v-icon>Delete
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog.show" width="300">
      <v-card>
        <v-card-title>{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button" icon outline @click="onDialogNo">
            <v-icon>mdi-close</v-icon>No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="button" icon outline @click="onDialogYes">
            <v-icon>mdi-delete</v-icon>Yes
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import router from "@/router"
import store from "@/store"

export default {
  created() {
    this.get();
  },

  data: () => ({
    dialog: {
      id: 0,
      description: "",
      title: "",
      show: false,
    },
    headers: [
      {
        text: "Key",
        align: "start",
        sortable: true,
        value: "key",
      },
      {
        text: "Value",
        align: "start",
        sortable: true,
        value: "value",
      },
      {
        text: "",
        filterable: false,
        sortable: false,
        value: "edit",
        width: 60,
      },
      {
        text: "",
        filterable: false,
        sortable: false,
        value: "delete",
        width: 60,
      },
    ],
    settings: [],
  }),

  methods: {
    get() {
      this.settings = [];
      apiService.get("registry", this.getSuccess, this.getError);
    },

    getError(error) {
      const notification = {
        title: "Problem getting Registry settings",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(result) {
      this.settings = result.data;
    },

    onDelete(id) {
      console.log("onDelete ", id);
      this.dialog.id = id;
      const setting = this.settings.find((s) => s.id == id);
      this.dialog.title = "Delete Registry Setting?";
      this.dialog.description =
        "Delete registry setting " +
        setting.key +
        " with value " +
        setting.value;
      this.dialog.show = true;
    },

    deleteError(error) {
      const notification = {
        title: "Problem getting Registry settings",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    deleteSuccess() {
      const notification = {
        title: "Setting Deleted",
        status: "success",
      };
      store.commit("setNotification", notification);
      this.get();
    },

    onDialogNo() {
      this.dialog.id = 0;
      this.dialog.show = false;
    },

    onDialogYes() {
      const url = "registry/" + this.dialog.id
      apiService.delete(url, this.deleteSuccess, this.deleteError)
      this.onDialogNo()
    },

    onEdit(id) {
      console.log("onEdit ", id)
      router.push("/registry/" + id)
    },
  },
};
</script>

<style>
.dialog {
  width: 400px;
  height: 200px;
}
</style>