<template>
  <v-container>
    <v-card v-if="editting" class="comment-ctrl" color="primary">
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <label>Comment</label>
          <vue-editor v-model="theComment.contents"></vue-editor>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined text @click="onCancel"
          ><v-icon left>mdi-cancel</v-icon>Cancel</v-btn
        >
        <v-btn outlined text @click="onSave"
          ><v-icon left>mdi-content-save</v-icon>Save</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-if="!editting" class="comment-ctrl" color="primary">
      <div>
        <v-row>
          <v-col cols="12" xl="10" lg="10" md="9" sm="8">
            <v-card-text v-html="theComment.contents"></v-card-text>
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4">
            <div class="detail">
              <v-row>
                <v-spacer></v-spacer>
                <div class="comment-detail" v-if="theComment.createdBy && theComment.createdBy.id != 0">
                  By: {{ theComment.createdBy.firstName }}
                  {{ theComment.createdBy.lastName }}
                </div>
                <div class="comment-detail" v-else>
                  By: Anonymous
                </div>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <div class="comment-detail">
                  Created: {{ theComment.createdOn.substring(0, 10) }}
                </div>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <div class="comment-detail">
                  Modified: {{ theComment.lastModifiedOn.substring(0, 10) }}
                </div>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn icon @click="addComment" v-if="allowAdd">
                  <v-icon left>mdi-plus</v-icon>
                </v-btn>
                <v-btn icon @click="onEdit()" v-if="allowEdit">
                  <v-icon left>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  :disabled="comments.length > 0"
                  icon
                  @click="onDelete()"
                  v-if="allowDelete"
                >
                  <v-icon left>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-row v-for="sub in comments" v-bind:comment="sub" v-bind:key="sub.id">
      <v-col cols="11" offset="1">
        <commentCtrl
          :level="level + 1"
          :comment="sub"
          @cancel="onCancelSub"
          @delete="onChildDeleted"
          @saved="onCommentSaved"
        ></commentCtrl>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import commentCtrl from "./Comment";
import apiService from "@/_services/api-service";
import registry from "@/_services/registry-service";
import store from "@/store";
import { VueEditor } from "vue2-editor";

export default {
  name: "commentCtrl",
  props: {
    comment: {
      required: true,
      default: {},
    },
    level: Number,
  },

  components: {
    VueEditor,
    commentCtrl,
  },

  created() {
    console.log("Comment.created() ", this.comment);
    this.isBlogger = this.$store.getters.isBlogger;
    this.isLoggedIn = this.$store.getters.getUser.LoggedIn;
    if (this.allowComments) {
      if (this.allowAnonymousComments || this.isLoggedIn) {
        this.allowAdd = true;
      }
    }
    this.allowDelete = this.isBlogger;
    this.allowEdit = this.isBlogger;
    this.theComment = {
      id: this.comment.id,
      postId: this.comment.postId,
      contents: this.comment.contents,
      userId: this.comment.userId,
      createdBy: this.comment.createdBy,
      parentId: this.comment.parentId,
      isPublished: this.comment.isPublished,
      createdOn: this.comment.createdOn,
      lastModifiedOn: this.comment.lastModifiedOn,
      publishedOn: this.comment.publishedOn,
    };
    this.editting = this.theComment.id == 0;
    if(!this.editting) {
      this.get()
    }
  },

  data: () => ({
    allowComments: registry.getBool("blog_allow_comments"),
    allowAnonymousComments: registry.getBool("blog_allow_anonymous_comments"),
    allowEdit: false,
    allowAdd: false,
    allowDelete: false,
    editting: true,
    isBlogger: false,
    theComment: {
      id: 0,
      postId: 0,
      contents: '',
      userId: 0,
      createdBy: null,
      parentId: 0,
      isPublished: false,
      createdOn: '',
      lastModifiedOn: '',
      publishedOn: '',

    },
    comments: [],
  }),

  methods: {
    addComment() {
      console.log("Comment.addComment()");
      const user = this.$store.getters.getUser;
      user.firstName = user.firstname;
      user.lastName = user.lastname;
      let userId = user == null ? null : user.id;
      this.newComment = {
        id: 0,
        postId: null,
        contents: "Sub Comment",
        createdBy: user,
        userId: userId,
        parentId: this.theComment.id,
        isPublished: true,
        createdOn: new Date().toISOString().substr(0, 10),
        lastModifiedOn: new Date().toISOString().substr(0, 10),
        publishedOn: new Date().toISOString().substr(0, 10),
      };
      this.comments.push(this.newComment);
    },

    get() {
      if (!this.editting) {
        const url = "Comment/GetChildren/" + this.theComment.id;
        apiService.get(url, this.getSuccess, this.getError);
      } else {
        const user = this.$store.getters.getUser;
        this.theComment.userId = user.userId
        this.theComment.createdBy = user;
      }
    },

    getError(error) {
      const notification = {
        title: "Error getting sub comments",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(result) {
      this.comments = result.data;
    },

    onChildDeleted(id) {
      const index = this.comments.map((c) => c.id).indexOf(id);
      if (index > -1) {
        this.comments.splice(index, 1);
      }
    },

    onCancel() {
      this.editting = false;
      if (this.theComment.id == 0) {
        this.$emit("cancel", this.comment.id);
      }
    },

    onCancelSub(id) {
      console.log("onCancel() ", id);
      this.comments.pop();
    },

    onCommentSaved(data) {
      const comment = this.comments.find(c => c.id = data.oldId)
      if(comment){
        comment.id = data.newId
      }
    },

    onDelete() {
      console.log("onDelete()");
      const url = "Comment/" + this.theComment.id;
      apiService.delete(url, this.deleteSuccess, this.deleteError);
    },

    deleteSuccess() {
      const notification = {
        title: "Comment Deleted",
        status: "success",
      };
      store.commit("setNotification", notification);
      this.$emit("delete", this.theComment.id);
    },

    deleteError(error) {
      const notification = {
        title: "Problem deleting the comment",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onEdit() {
      console.log("onEdit()");
      this.editting = true;
    },

    onSave() {
      this.editting = false;
      if (this.theComment.id == 0) {
        const url = "Comment";
        apiService.post(url, this.theComment, this.saveSuccess, this.saveError);
      } else {
        const url = "Comment/" + this.theComment.id;
        apiService.put(url, this.theComment, this.saveSuccess, this.saveError);
      }
    },

    saveError(error) {
      const notification = {
        title: "Problem saving the comment",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    saveSuccess(result) {
      this.editting = false;
      const data = {
        oldId: this.theComment.id,
        newId: result.data.id,
      }
      this.$emit("saved", data);
      const user = this.$store.getters.getUser;
      user.firstName = user.firstname;
      user.lastName = user.lastname;
      this.theComment.id = result.data.id
      this.theComment.userId = user.userId
      this.theComment.createdBy = user;
      console.log("saveSuccess() ", this.theComment.id);
      const notification = {
        title: "Comment saved",
        status: "success",
      };
      store.commit("setNotification", notification);
    },
  },
};
</script>

<style>
.comment-ctrl {
  margin-left: 50px;
  margin-top: 5px;
  padding: 5px;
  width: 90%;
}
.comment-detail {
  font-size: 0.65em;
}
.detail {
  padding: 15px;
}
</style>