<template>
  <v-container>
    <h3 v-if="allowAdd">Tags</h3>
    <v-autocomplete
      chips
      deletable-chips
      :hint="
        allowAdd
          ? 'Tags must be 32 characters or less. Hit enter to add tag.'
          : 'Tags must be 32 characters or less.'
      "
      multiple
      label="Tags"
      small-chips
      item-text="title"
      itemid="id"
      return-object
      :items="tags"
      :search-input.sync="currentText"
      v-model="selected"
      @keypress="onKeypress"
      @change="onChange"
      @blur="onBlur"
    ></v-autocomplete>
  </v-container>
</template>

<script>
import ApiService from "@/_services/api-service"
import store from "@/store"

export default {
  props: {
    allowAdd: {
      required: false,
      default: false,
      type: Boolean,
    },
    selectedTags: {
      required: false,
    },
  },

  created() {},

  data: () => ({
    selected: [],
    tags: [],
    currentText: "",
  }),

  methods: {
    get() {
      ApiService.get("tag", this.getSuccess, this.getError);
    },

    getError() {
      const notification = {
        title: "Error getting the tags",
        error: null,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(response) {
      this.tags = response.data;
      this.tags.sort((a, b) => (a.title > b.title ? 1 : -1));
      this.selected = store.getters.getPostSelectedTags
      this.onChange()
    },

    onBlur() {
      this.$emit("blur");
    },

    onChange() {
      this.$emit("change", this.selected);
    },

    onKeypress(key) {
      if (key.charCode == 13 && this.allowAdd) {
        console.log("Text: ", this.currentText);
        if (this.currentText.length <= 32) {
          const tag = {
            id: 0,
            createdOn: new Date().toISOString().substr(0, 10),
            description: "",
            isPublished: true,
            publishedOn: new Date().toISOString().substr(0, 10),
            lastModifiedOn: new Date().toISOString().substr(0, 10),
            title: this.currentText,
            slug: "",
          };
          this.tags.push(tag);
          this.selected.push(tag);
          this.currentText = "";
        }

        this.onChange();
      }
    },
  },

  mounted() {
    this.get();
  },
};
</script>

<style lang="scss" scoped>
</style>