<template>
  <v-card>
    <v-card-title>{{ video.title }}</v-card-title>
    <v-card-subtitle>{{ video.description }}</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          This is a video click details to view
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon v-if="allowView" @click="onDetail">
        <v-icon>mdi-camera-image</v-icon>
      </v-btn>
      <v-btn icon v-if="allowEdit" @click="onEdit">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon v-if="allowDelete" @click="onDelete">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import route from "@/router";
import store from "@/store";

export default {
  props: {
    allowDelete: {
      required: false,
      default: true,
    },
    allowEdit: {
      required: false,
      default: true,
    },
    allowView: {
      required: false,
      default: true,
    },
    video: {
      required: true,
    },
  },

  data: () => ({
  }),

  methods: {
    onDelete() {
      this.$emit("delete", this.video.id);
    },

    onDetail() {
      route.push('video/' + this.video.id);
    },

    onEdit() {
      route.push("video/edit/" + this.video.id);
    },

    onAdd() {
      route.push("video/edit/0");
    },
  },

  route,
  store,
};
</script>

<style>
.video {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>