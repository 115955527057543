<template>
  <v-container>
    <h1>Posts</h1>
    <v-data-table :headers="headers" :items="posts" item-key="id">
      <template v-slot:[`header.edit`]>
        <v-btn
          outlined
          @click="onEdit(0)"
        >
          <v-icon left>mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <template v-slot:[`item.isPublished`]="{ item }">
        <v-simple-checkbox
          v-model="item.isPublished"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.name`]="{ item }"
        >{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</template
      >
      <template v-slot:[`item.commentCount`]="{ item }">{{
        item.comments.length
      }}</template>
      <template v-slot:[`item.tagCount`]="{ item }">{{
        item.tags.length
      }}</template>
      <template v-slot:[`item.categoryCount`]="{ item }">{{
        item.categories.length
      }}</template>
      <template v-slot:[`item.createdDate`]="{ item }">{{
        convertDate(item.createdOn, false)
      }}</template>
      <template v-slot:[`item.publishedDate`]="{ item }">{{
        convertDate(item.publishedOn, false)
      }}</template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn outlined @click="onEdit(item.id)">
          <v-icon left>mdi-pencil</v-icon>Edit
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn outlined @click="onDelete(item.id)">
          <v-icon left>mdi-delete</v-icon>Delete
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import dateService from "@/_services/date-service"
import router from "@/router"
import store from "@/store"

export default {
  created() {
    this.get();
  },

  data: () => ({
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
      },
      {
        text: "Description",
        align: "start",
        sortable: true,
        value: "description",
      },
      {
        text: "Slug",
        align: "start",
        sortable: true,
        value: "slug",
      },
      {
        text: "Created By",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Published",
        align: "start",
        sortable: true,
        value: "isPublished",
      },
      {
        text: "Created On",
        align: "end",
        sortable: true,
        value: "createdDate",
      },
      {
        text: "Published On",
        align: "end",
        sortable: true,
        value: "publishedDate",
      },
      {
        text: "Comments",
        align: "end",
        sortable: true,
        value: "commentCount",
      },
      {
        text: "Tags",
        align: "end",
        sortable: true,
        value: "tagCount",
      },
      {
        text: "Categories",
        align: "end",
        sortable: true,
        value: "categoryCount",
      },
      {
        text: "",
        filterable: false,
        sortable: false,
        value: "edit",
        width: 60,
      },
      {
        text: "",
        filterable: false,
        sortable: false,
        value: "delete",
        width: 60,
      },
    ],
    posts: [],
  }),

  methods: {
    convertDate(dateString, full) {
      return dateService.convertDate(dateString, full);
    },
    get() {
      apiService.get("post", this.getSuccess, this.getError);
    },

    getError(error) {
      const notification = {
        title: "Problem getting Posts",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(result) {
      this.posts = result.data;
    },

    onDelete(id) {
      console.log("onDelete ", id);
    },

    onEdit(id) {
      console.log("onEdit ", id)
       router.push('/blog/edit/' + id)
    },
  },
};
</script>

<style>
</style>