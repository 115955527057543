<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="button" icon v-if="allowAdd" @click="onAdd">
        <v-icon>mdi-plus</v-icon>Add Image
      </v-btn>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="4"
        sm="6"
        v-for="image in images"
        v-bind:key="image.id"
      >
        <div v-if="image.type == 'image/jpeg'">
          <image-card :image="image" @delete="onDelete(image.id)"></image-card>
        </div>
        <div v-if="image.type == 'image/png'">
          <image-card :image="image" @delete="onDelete(image.id)"></image-card>
        </div>
        <div v-if="image.type == 'video/mp4'">
          <image-card :image="image" @delete="onDelete(image.id)"></image-card>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>Delete Image</v-card-title>
        <v-card-subtitle>Title: {{ image.title }}</v-card-subtitle>
        <v-card-text>Description: {{ image.description }}</v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="button" icon outline @click="onNo">
              <v-icon>mdi-close</v-icon>No
            </v-btn>
            <v-btn class="button" icon outline @click="onYes">
              <v-icon>mdi-delete</v-icon>Yes
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import imagecard from "@/views/Images/Card";
import router from "@/router";
import store from "@/store";

export default {
  components: {
    "image-card": imagecard,
  },

  created() {
    this.get();
  },

  data: () => ({
    allowAdd: true,
    image: {},
    images: [],
    showDialog: false,
    url: "image",
  }),

  methods: {
    get() {
      this.images = [];
      apiService.get("image", this.getSuccess, this.getError);
    },

    getSuccess(result) {
      this.images = result.data;
    },

    getError(error) {
      const notification = {
        title: "Error getting the images",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onAdd() {
      router.push("/image/edit/0");
    },

    onDelete(id) {
      console.log("Delete : ", id);
      this.showDialog = true;
      this.image = this.images.find((i) => i.id == id);
    },

    onNo() {
      this.showDialog = false;
    },

    onYes() {
      this.showDialog = false;
      const url = "image/" + this.image.id;
      apiService.delete(url, this.deleteSuccess, this.deleteError);
    },

    deleteSuccess() {
      this.showDialog = false;
      const notification = {
        title: "Image Deleted",
        status: "success",
        messages: [this.image.title + " deleted"],
      };
      store.commit("setNotification", notification);
      this.get();
    },

    deleteError(error) {
      const notification = {
        title: "Error deleting the image",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },

  router,
  store,
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 50px;
}
</style>