<template>
  <v-form>
    <v-row>
      <v-col cols="12" xl="5" lg="5" md="5" sm="12">
        <v-text-field v-model="post.title" label="Title"></v-text-field>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="12">
        <v-checkbox
          v-model="post.isPublished"
          label="Is Published"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12">
        <category-ctrl :allowAdd='true' :selectedCategories="post.categories" @change="onCategoriesChanged"></category-ctrl>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12">
        <tag-ctrl :allowAdd='true' :selectedTags="post.tags" @change="onTagsChanged"></tag-ctrl>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="10" lg="10" md="10" sm="12">
        <v-text-field
          v-model="post.description"
          label="Description"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12">
        <v-text-field v-model="post.slug" disabled label="Slug"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <label>Created On</label><br />
        <v-date-picker
          v-model="createdOn"
        ></v-date-picker>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <label>Published On</label><br />
        <v-date-picker
          v-model="publishedOn"
        ></v-date-picker>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <label>Last Modified On</label><br />
        <v-date-picker disabled
          v-model="lastModifiedOn"
        ></v-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <label>Contents</label>
        <vue-editor v-model="post.contents"></vue-editor>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn outlined class="button" @click="onCancel()">
        <v-icon left>mdi-cancel</v-icon>Cancel
      </v-btn>
      <v-btn outlined class="button" @click="onSave()">
        <v-icon left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div v-html='post.contents'>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import apiService from "@/_services/api-service"
import store from "@/store"
import router from "@/router"
import { VueEditor } from "vue2-editor"
import categories from '@/components/controls/Categories'
import tags from '@/components/controls/Tags'

export default {
  components: {
    VueEditor,
    'category-ctrl': categories,
    'tag-ctrl': tags
  },

  created() {
    this.get()
  },
  computed: {},

  data: () => ({
    title: "Edit Post",
    createdOn: new Date().toISOString().substr(0, 10),
    publishedOn: new Date().toISOString().substr(0, 10),
    lastModifiedOn: new Date().toISOString().substr(0, 10),
    post: {
      id: 0,
      categories: [],
      contents: "",
      createdOn: '',
      description: "",
      isPublished: false,
      lastModifiedOn: '',
      publishedOn: '',
      slug: "",
      tags: [],
      title: "",
      userId: store.getters.getUser.id
    },
  }),

  methods: {
    get() {
      this.title = this.$route.params.id == 0 ? "Add Post" : "Edit Post"
      const url = "post/" + this.$route.params.id
      apiService.get(url, this.getSuccess, this.getError)
    },

    getError(error) {
      console.log(error)
    },

    getSuccess(result) {
      this.post = result.data;
      this.createdOn = new Date(this.post.createdOn)
        .toISOString()
        .substr(0, 10);
      this.publishedOn = new Date(this.post.publishedOn)
        .toISOString()
        .substr(0, 10);
      this.lastModifiedOn = new Date(this.post.lastModifiedOn)
        .toISOString()
        .substr(0, 10);
    },

    onCancel() {
      router.go(-1)
    },

    onCategoriesChanged(categories) {
      this.post.categories = categories
    },

    onSave() {
      this.post.userId = store.getters.getUser.id
      this.post.createdOn = this.createdOn
      this.post.lastModifiedOn = this.lastModifiedOn
      this.post.publishedOn = this.publishedOn
      if(this.post.id == 0) {
        this.post.slug = apiService.baseURL() + 'post/'
        //this.post.createdBy = store.getters.getUser
        apiService.post('post', this.post, this.saveSuccess, this.saveError)
      } else {
        const url = 'post/' + this.post.id
        apiService.put(url, this.post, this.saveSuccess, this.saveError)
      }
    },

    onTagsChanged(tags) {
      this.post.tags = tags
    },

    saveError(error) {
      console.log(error)
    },

    saveSuccess(result) {
      console.log(result)
      router.go(-1)
    }
  },
  router,
  store,
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 20px;
}
</style>