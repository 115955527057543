<template>
  <v-container>
    <v-card color="primary">
      <v-card-title>
        <pagetitle icon="mdi-account-cog-outline" title="Profile"></pagetitle>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field color="accent"
                label="Username"
                prepend-icon="mdi-account-circle"
                v-model="user.userName"
                required
                :rules="userNameRules"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                label="Email"
                prepend-icon="mdi-at"
                v-model="user.email"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                prepend-icon="mdi-card-account-phone"
                label="Phone"
                v-model="user.phoneNumber"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
            <v-btn outlined @click="onSave">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import pagetitle from "../../components/controls/Title"
import store from "@/store"

export default {
  name: "accountprofile",
  data() {
    return {
      user: {
        userId: 0,
        userName: "",
        email: "",
        mobileAlias: "",
      },
      valid: false,
    userNameRules: [(v) => !!v || "Username is required"],
    };
  },
  components: {
    pagetitle,
  },
  created() {},

  methods: {
    onSave() {},
  },

  store: store,
};
</script>
<style lang="scss" scoped>
.v-card {
  margin-right: 15px;
}

.v-btn {
  margin-right: 20px;
  margin-bottom: 10px;
}
</style>